<script setup lang="ts">
import TextInput from '~/components/atoms/form/TextInput.vue'
import {useDomainDataStore} from '~/stores/domain'

const {signIn, isPending, authError, resetAuthError} = useSession()
const route = useRoute()
const domainStore = useDomainDataStore()

const usernameInput = ref<typeof TextInput|null>(null)
const username = ref<string>('')
const password = ref<string>('')

definePageMeta({
  layout: 'login',
  publicAccess: true,
})

const canonical = computed(() => domainStore.wwwBaseUrl + route.path)

useHead({
  title: useNuxtApp().$i18n.t('login.title'),
  link: computed(() => [
    { rel: 'canonical', href: canonical.value },
  ]),
})

useSeoMeta({
  ogUrl: canonical,
})

const disabled = computed(() => {
  return username.value === '' || password.value === ''
})

async function onSubmit() {
  const success = await signIn(username.value, password.value)
  if (success === false) {
    password.value = ''
    usernameInput.value?.select()
  }
}
</script>

<template>
  <form class="w-full max-w-md" @submit.prevent="onSubmit" :class="{'opacity-50': isPending}">
    <AtomsHeadingsLarge tag="h1" class="mb-10">{{ $t('login.header') }}</AtomsHeadingsLarge>
    <AtomsFormTextInput class="mb-5" :label="$t('login.username')" :autofocus="true" autocapitalize="off" autocomplete="email,username" v-model="username" ref="usernameInput" @update:modelValue="resetAuthError" :required="true" />
    <AtomsFormTextInput class="mb-5" :label="$t('login.password')" type="password" autocomplete="current-password" v-model="password" @update:modelValue="resetAuthError" :required="true">
      <template #after-input>
        <NuxtLink class="text-button-small underline" :to="localePath('password-reset')">{{ $t('login.password_forgotten') }}</NuxtLink>
      </template>
    </AtomsFormTextInput>
    <AtomsFormError :text="authError" translate-key-prefix="login.error" />
    <div class="mt-5 text-right">
      <AtomsButton :label="$t('login.connection')" dark-color="domain" tag="button" :disabled="disabled" />
    </div>
  </form>
</template>